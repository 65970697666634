import { type FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { type IProductResponse } from '@shared/typings'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { dateFormat } from '@shared/constants'
import { messages } from '../pages/customerAccount/customerAccountPage'
import { ProductCard, ProductWrap } from './productStyle'
import {
  formatedDate,
  convertToAmount,
  AccountType,
  ProductStatus,
  hasNoPlayground,
  openInNextWindow,
  getNextScreenUrl
} from 'shared'
import {
  Box,
  Typography,
  Grid,
  Button,
  TooltipProgressBar,
  Icon,
  Menu
} from '@ntpkunity/controls'
import EmptyState from './emptyState'
import { type IMenuItemOptions } from '@ntpkunity/controls/dist/cjs/components/Menu/Menu.types'
import { Product } from '@shared/constants/enums'

interface IFreeTrialProductsProps {
  products: IProductResponse[] | undefined
}

export const FreeTrialProducts: FC<IFreeTrialProductsProps> = ({
  products
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [freeTrialProducts, setFreeTrialProducts] = useState(products)

  const menuOptions = (product: IProductResponse): IMenuItemOptions[] => {
    const options: IMenuItemOptions[] = []
    const { product_path: productPath, product_name: productName, documentation_url: documentationUrl, user_manual_url: UserManual } = product
    if (productName !== Product.DRIFT) {
      if ((productPath ?? '').length > 0) {
        options.push({
          optionText: hasNoPlayground(productName)
            ? messages.button.gotoApp
            : messages.button.playground,
          optionkey: `${process.env.NEXT_SCREEN_BASE_URL as string}/${productPath}`
        })
      }
      if ((documentationUrl ?? '').length > 0) {
        options.push(
          {
            optionText: 'API Documentation',
            optionkey: `${process.env.DOCUMENTATION as string}${documentationUrl}`
          }
        )
      }
      // if ((UserManual ?? '').length > 0) {
      //   options.push(
      //     {
      //       optionText: 'User Manual',
      //       optionkey: `${process.env.USER_MANUAL_URL as string}${UserManual}`
      //     }
      //   )
      // }
    }
    return options
  }

  const handleSelection = (value, key): void => {
    openInNextWindow(key)
  }
  // const {
  //   states: { pusherChannel },
  // } = useStoreContext();

  // useEffect(() => {
  //   if (pusherChannel.pusherChannel) {
  //     pusherChannel.pusherChannel.bind(CALCULATION_PERFORMED, (data) => {
  //       if (data.is_success) {
  //         setFreeTrialProducts(
  //           products?.map((product) => {
  //             if (product.price_id === data.price_id) {
  //               product.transactions_performed = data.transactions_performed;
  //               product.remaining_transactions = data.remaining_transactions;
  //             }
  //             return product;
  //           })
  //         );
  //       }
  //     });
  //   }
  // }, [pusherChannel]);

  useEffect(() => {
    if ((products ?? []).length > 0) setFreeTrialProducts(products)
  }, [products])

  // const getDayDiff = (startDate: Date, endDate: Date) => {
  //   const msInDay = 24 * 60 * 60 * 1000
  //   const freeTrialRemaingDays = Math.round(
  //     Math.abs(endDate.getTime() - startDate.getTime()) / msInDay
  //   )

  //   return freeTrialRemaingDays <= 1
  //     ? '1 day.'
  //     : freeTrialRemaingDays + ' days.'
  // }

  return (
    <>
      <ProductWrap theme={theme} className="products-wrap">
        <Grid theme={theme} container item spacing={4} pb={{ md: 10, sm: 6 }}>
          {(freeTrialProducts ?? [])?.length > 0
            ? (
                freeTrialProducts?.map((product) => {
                  const excessTransactions =
                  product.transactions_performed - product.allowed_transactions
                  const excessTransactionPercentage = Math.min(
                    (excessTransactions / product.allowed_transactions) * 100,
                    100
                  )
                  const transactionsPercentage =
                  (product.remaining_transactions /
                    product.allowed_transactions) *
                  100
                  return (
                  <Grid
                    key={product.id}
                    theme={theme}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                  >

                    <ProductCard
                      theme={theme}
                      className="product-card"
                      mt={1}
                      mb={3}
                    >
                      <Box theme={theme} className="prd-img" mb={3}>
                        <img src={product.image_url} alt="free-trial-image" />
                        {/* <Box theme={theme} className="prd-overlay-content">
                        {product.status === ProductStatus.EXPIRE ? (
                          <Typography
                            className="text-white"
                            theme={theme}
                            variant="subtitle1"
                            component="p"
                          >
                            Your trial is expired
                          </Typography>
                        ) : (
                          <Typography
                            className="text-white"
                            theme={theme}
                            variant="subtitle1"
                            component="p"
                          >
                            Your free trial will expire in &nbsp;
                            {getDayDiff(new Date(), new Date(product.end_date))}
                          </Typography>
                        )}
                        <Button
                          fullWidth
                          secondary
                          theme={theme}
                          disabled={product.status === ProductStatus.EXPIRE}
                          text={messages.button.documentation}
                          onClick={() =>
                            openInNextWindow(
                              `${process.env.DOCUMENTATION}${product.documentation_url}`
                            )
                          }
                        ></Button>
                        {product?.product_path ? (
                          <Button
                            fullWidth
                            secondary
                            theme={theme}
                            disabled={product.status === ProductStatus.EXPIRE}
                            text={
                              productHasNoPlayground
                                ? messages.button.gotoApp
                                : messages.button.playground
                            }
                            onClick={() =>
                              openInNextWindow(
                                `${process.env.NEXT_SCREEN_BASE_URL}/${product?.product_path}`
                              )
                            }
                          ></Button>
                        ) : null}
                      </Box> */}
                      </Box>
                      <Box theme={theme} className="prd-content-wrap">
                        <Typography theme={theme} variant="h4" component="h4">
                          {product.product_name}
                        </Typography>
                        <Box theme={theme} className="prod-details">
                          <Box theme={theme} className="item" mt={1}>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="caption"
                              component="small"
                            >
                              {messages.label.status}
                            </Typography>
                            <Typography
                              className="text-muted"
                              ml={1}
                              theme={theme}
                              variant="caption"
                              component="small"
                            >
                              {product.status}
                            </Typography>
                          </Box>
                          <Box theme={theme} className="item" mt={1}>
                            <Typography
                              className="text-muted"
                              theme={theme}
                              variant="caption"
                              component="small"
                            >
                              {messages.label.expiryDate}
                            </Typography>
                            <Typography
                              className="text-muted"
                              ml={1}
                              theme={theme}
                              variant="caption"
                              component="small"
                            >
                              {formatedDate(product.end_date, dateFormat)}
                            </Typography>
                          </Box>
                          {excessTransactions <= 0 && (
                            <Box theme={theme} className="item" mt={1}>
                              <Typography
                                className="text-muted"
                                theme={theme}
                                variant="caption"
                                component="small"
                              >
                                {messages.label.transactions}
                              </Typography>
                              <Typography
                                className="text-muted"
                                ml={1}
                                theme={theme}
                                variant="caption"
                                component="small"
                              >
                                {convertToAmount(product.allowed_transactions)}
                              </Typography>
                            </Box>
                          )}
                          {excessTransactions > 0 && (
                            <Box theme={theme} className="item" mt={1}>
                              <Typography
                                className={`${excessTransactions > 0 ? 'text-danger' : ''}`}
                                theme={theme}
                                variant="caption"
                                component="small"
                              >
                                {messages.label.excessTransactions}
                              </Typography>
                              <Typography
                                className={`${excessTransactions > 0 ? 'text-danger' : ''}`}
                                ml={1}
                                theme={theme}
                                variant="caption"
                                component="small"
                              >
                                {convertToAmount(excessTransactions)}
                              </Typography>
                            </Box>
                          )}
                          <Box
                            theme={theme}
                            className={`progress-wrap ${excessTransactions > 0 ? 'progress-danger' : ''}`}
                            mt={1}
                          >
                            <TooltipProgressBar
                              theme={theme}
                              value={
                                excessTransactions > 0
                                  ? excessTransactionPercentage
                                  : transactionsPercentage
                              }
                              title={
                                convertToAmount(
                                  excessTransactions > 0
                                    ? excessTransactions
                                    : product.remaining_transactions
                                ) as any
                              }
                            />
                          </Box>
                          <Box theme={theme} mt={3} className="prd-action is-unsubscribed">
                            <Button
                              fullWidth
                              theme={theme}
                              primary
                              disabled={
                                product.status === ProductStatus.EXPIRE &&
                                !product.is_active
                              }
                              text={messages.button.subscribe}
                              onClick={() => {
                                navigate(
                                  `${APP_ROUTES.DETAIL_PAGE}/${product.product_name}`,
                                  {
                                    state: { accountType: AccountType.FREE }
                                  }
                                )
                              }
                              }
                            ></Button>
                            <Menu theme={theme}
                              disablePortal={true}
                              options={menuOptions(product)}
                              handleOptionClick={handleSelection}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                              render={cb => <Button theme={theme} secondary iconText={<Icon name="MoreIcon" />} onClick={cb} />}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </ProductCard>
                  </Grid>
                  )
                })
              )
            : (
              <EmptyState isTrialTab={true} />
              )}
        </Grid>
      </ProductWrap>
    </>
  )
}
