export enum OrderState {
  DRAFT = 'Draft',
  NEW = 'New',
  CANCEL = 'Cancel',
}

export enum Theme {
  DARK = 'dark',
  DEFAULT = 'default',
}

export enum AccountType {
  FREE = 'free',
  PAID = 'paid',
}

export enum HttpStatus {
  OK = 200,
  Conflict = 409,
  Bad_Request = 404,
  Locked = 423,
}

export enum ReceiptStatus {
  PAID = 'paid',
  FAILED = 'failed',
  DRAFT = 'draft',
  VOID = 'void',
}

export enum Validation {
  REQUIRED = 'required',
  EMAIL = 'email',
  WHITE_SPACE = 'whiteSpace',
  ONLY_ALPHABETS = 'onlyAlphabets',
  PHNONE_NUMBER = 'phoneNumber',
  LIMIT_LENGTH = 'limitLength',
  CROSS_VALIDATE = 'crossValidate',
}

export enum NotificationType {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export enum CardType {
  VISA = 'visa',
  MASTERCARD = 'masterCard',
}

export enum HeaderOption {
  LOGOUT = 'Logout',
  UPDATE_PAYMENT_METHOD = 'Update Payment Method',
  TRANSACTION_HISTORY = 'Transaction History',
  CREATE_USER = 'User Management',
  CHANGE_PASSWORD = 'Change Password',
  CREATE_ACCOUNT = 'Create Account',
  LOGIN = 'Login',
  MY_PROFILE = 'My Profile',
}

export enum UserAction {
  EDIT = 'Edit',
  CREATE = 'Create',
}

export enum IconType {
  SUCCESS = 'SuccessIcon',
  ALERT = 'DialogAlertIcon',
  DELETE = 'DialogDeleteIcon',
}

export enum ButtonType {
  CONTINUE = 'Continue',
  CLOSE = 'Close',
  DONE = 'Done',
  DELETE = 'Delete',
  CANCEL = 'Cancel',
  SAVE = 'Save Changes',
  UNSUBSCRIBE = 'Unsubscribe'
}

export enum DialogHeading {
  CONGRATULATIONS = 'Congratulations!',
  ERROR = 'Error Occurred',
  THANKS = 'Thanks!',
  DELETE = 'Are you sure you want to delete?',
  UPDATE = 'Are you sure you want to update?',
  UNSUBSCRIBE = 'Are you sure you want to unsubscribe?'
}

export enum ProductStatus {
  ACTIVE = 'Active',
  EXPIRE = 'Expired',
  INACTIVE = 'InActive',
}

export enum TermOfServices {
  PRINT = 'Print',
  DOWNLOAD = 'Download',
}

export enum SidebarMenu {
  DASHBOARD = 'Dashboard',
  MY_ACCOUNT = 'My Account',
  BILLING_DETAILS = 'Billing Details',
  TRANSACTION_HISTORY = 'Transaction History',
  SUBSCRIBED_PRODUCTS = 'Subscribed Products',
  MANAGE_USERS = 'Manage Users',
  EMAIL_PREFERENCES = 'Email Preferences',
}

export enum ProductTabName {
  FREE_TRIAL_PRODUCTS = 0,
  SUBSCRIBED_PRODUCTS = 1,
}

export enum MenuOptions {
  VIEW_RECEIPT = 'view_receipt',
  VIEW_INVOICE = 'view_invoice',
  DOWNLOAD_INVOICE = 'download_invoice',
  DOWNLOAD_RECEIPT = 'download_receipt',
}

export enum Product {
  FLEX = 'Flex',
  DEALEX = 'DealEx',
  INDEX = 'IndEx',
  HUBEX = 'HubEx',
  TAX_ENGINE = 'Tax Engine',
  SUPPORT_SERVICES = 'Support Services',
  DRIFT = 'Drift'
}

export enum ActionButton {
  LOGIN = 'Sign In',
  LOGOUT = 'Logout',
}

export enum Steps {
  REGISTERED = 'registered',
  VERIFIED = 'verified',
  TENANT = 'tenant',
  TERMS = 'terms',
}

export enum QueryKeys {
  GET_ALL_USER_CONDITIONS_FILTER = 'GET ALL USER',
}

export enum UserType {
  ADMIN = 'admin',
  FLEX_USER = 'flex user',
}

export enum Category {
  PRODUCTS = 'products',
  ADD_ONS = 'add-ons',
  SERVICES = 'services',
  BUNDLES = 'bundles',
}

export enum ProductActions {
  UNSUBSCRIBE = 'Unsubscribe',
  PLAYGROUND = 'Playground',
  GO_TO_APP = 'Go To App',
  DOCUMENTATION_URL = 'API Documentation',
  USER_MANUAL = 'User Manual'

}
export enum PasswordKey {
  Text = 'text',
  Password = 'password',
}

export enum feedbackOptionsValue {
  'AMAZING' = 'Amazing',
  'GOOD' = 'Good',
  'OKAY' = 'Okay',
  'BAD' = 'Bad',
  'TERRIBLE' = 'Terrible',
}

export enum feedbackFollowUp {
  'YES' = 'Yes',
  'NO' = 'No',
}

export enum gridLayoutFormat {
  'COL_6' = '6',
  'COL_12' = '12'
}

export enum productState {
  LIVE = 'Live',
  UNDER_DEVELOPMENT = 'Under Development'
}

export enum PaymentMethodType {
  CARD = 0,
  BANK_TRANSFER = 1,
}

export enum PaymentMethodStatus {
  APPROVED = 'Approved',
  DECLINED = 'Declined',
  PENDING = 'Pending'
}

export enum PaymentMethod {
  CARD = 'Card',
  BANK_TRANSFER = 'BankTransfer',
}

export enum PaymentFrequency {
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  HALF_YEARLY = 'Half Yearly',
  YEARLY = 'Yearly'
}
